<template>
  <CardToolbar
    @days-changed="changeDaysValue"
    description="The distribution of Artist's monthly listeners across countries"
    title="Monthly Listeners by Country"
    :show-toolbar-buttons="true"
  >
    <template v-slot:body>
      <el-skeleton v-if="loading" :rows="10" animated />
      <el-row v-else>
        <el-col :xs="24" :lg="12">
          <ListenersTable
            :data="monthlyListeners"
            :table-headers="tableHeaders"
          />
        </el-col>
        <el-col :xs="24" :lg="12">
          <BarGraph
            :data="monthlyListenersChartData"
            title="No. of monthly listeners"
          />
        </el-col>
      </el-row>
    </template>
  </CardToolbar>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";

import ListenersTable from "./ListenersTable";
import BarGraph from "./BarGraph";
import CardToolbar from "../../common/components/CardToolbar";

import { getSpotifyListenersByCountry } from "@/api/artists.api";

export default {
  components: {
    ListenersTable,
    BarGraph,
    CardToolbar,
  },

  setup() {
    const route = useRoute();
    const loading = ref(true);
    const monthlyListeners = ref({ countries: [], followers: [] });
    const monthlyListenersChartData = ref({ countries: [], followers: [] });
    const tableHeaders = ["Rank", "Country", "Listeners"];

    onMounted(async () => {
      await getSpotifyMonthlyListenersData(route.params.uuid, 7, false);
    });

    async function getSpotifyMonthlyListenersData(uuid, days, isYearData) {
      try {
        loading.value = true;
        const { data } = await getSpotifyListenersByCountry(
          uuid,
          days,
          isYearData
        );
        monthlyListeners.value = {
          countries: data.xAxis,
          followers: data.yAxis,
        };
        monthlyListenersChartData.value = {
          countries:
            data.xAxis.length > 10 ? data.xAxis.slice(0, 10) : data.xAxis,
          followers:
            data.yAxis.length > 10 ? data.yAxis.slice(0, 10) : data.yAxis,
        };
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    }

    async function changeDaysValue(day) {
      await getSpotifyMonthlyListenersData(route.params.uuid, day, day === 365);
    }

    return {
      monthlyListeners,
      monthlyListenersChartData,
      loading,
      tableHeaders,
      changeDaysValue,
    };
  },
};
</script>

<style lang="scss" scoped></style>
